import { useContext } from 'react';
import {
  RetailersContext,
  SearchInputContext,
  StoreContext,
} from 'src/providers';
import { SelectorContext } from 'src/providers/SelectorProvider';

export const useStore = () => useContext(StoreContext);
export const useRetailers = () => useContext(RetailersContext);
export const useSearchInput = () => useContext(SearchInputContext);
export const useSelectorSettings = () => useContext(SelectorContext);
