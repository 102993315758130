import { getElasticVariablesFromEnv } from '../../common/getElasticVariablesFromEnv';
export function getVariablesFromPublicConfig(
  variables?: ReturnType<typeof getElasticVariablesFromEnv>,
) {
  return {
    environment: variables?.environment,
    logLevel: variables?.logLevel,
    serverUrl: variables?.serverUrl,
    serviceName: variables?.serviceName,
    serviceVersion: variables?.serviceVersion,
    serviceApiKey: variables?.serviceApiKey,
  };
}
