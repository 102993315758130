import { SiteSlug } from '@volvo-cars/market-sites';

export function getLaunchDarklyUser(siteSlug: SiteSlug) {
  return {
    kind: 'user',
    key: getLaunchDarklyUserKey(siteSlug),
    siteSlug,
  };
}

export function getLaunchDarklyUserKey(siteSlug: SiteSlug, userKey?: string) {
  return userKey ?? `dealer-locator-${siteSlug}`;
}
