import { getLanguageDisplayName } from './getLanguageDisplayName';
import { getLocaleFromLanguageCodeAndRegionCode } from './getLocaleFromLanguageCodeAndRegionCode';
import { getSiteSlug } from './getSiteSlug';
import { LanguageCode, languages } from './languages';
import { markets, RegionCode } from './markets';
import {
  sites,
  SiteSlug,
  siteSlugByLanguageCode,
  siteSlugByLocale,
  siteSlugByRegionCode,
} from './sites';
import { RoadLengthUnit, roadLengthUnitByRegion } from './units';
import { isValidSiteSlug } from './validation';

export class MarketSite {
  /**
   * Unique URL friendly identifier of the market site. The first part of the pathname on www.volvocars.com.
   *
   * undefined if no matching Market Site was found.
   */
  readonly siteSlug?: SiteSlug;

  /**
   * URL to the home page of the market site, e.g. /uk.
   */
  readonly href!: string;

  /**
   * Region or country name in English, e.g. "Canada", "Sweden".
   *
   * Usually a country, with exceptions such as Hong Kong (China) and Special Sales markets.
   */
  readonly marketName?: string;

  /**
   * Market region or country code.
   */
  readonly regionCode?: RegionCode;

  /**
   * Name of the market site's language in English.
   */
  readonly languageName: string;

  /**
   * Name of the market site's language in the native language.
   */
  readonly localizedLanguageName!: string;

  /**
   * Name of the market in the native language.
   */
  readonly localizedMarketName?: string;

  /**
   * Writing direction of the market site's language.
   */
  readonly languageDirection!: 'ltr' | 'rtl';

  /**
   * The two-letter ISO 639-1 language code part of the locale without script
   * or region, e.g. 'en', 'sv'.
   *
   * Useful for some APIs that don't accept locales with regions.
   */
  readonly languageCode!: string;

  /**
   * A Unicode BCP 47 locale identifier consisting of
   *
   *  - An ISO 639-1 or ISO 639-2 language code (e.g. 'en')
   *  - (optionally) a script code (e.g. 'Latn')
   *  - A region (or country) code (e.g. 'US')
   *
   * This is what you should use with browser APIs such as `Intl`.
   *
   * e.g. 'sr-Latin-RS', 'en-US'
   */
  readonly locale!: string;

  /**
   * A locale that's only includes the region suffix if required to distinguish
   * between different market sites. Suitable for `<html lang="">`.
   */
  readonly htmlLanguage!: string;

  /**
   * Whether or not the market/region has more sites in different languages.
   */
  readonly multilingual?: boolean;

  /**
   * Road length unit for region.
   */
  readonly roadLengthUnit!: RoadLengthUnit;

  /**
   * Country currency code (ISO 4127)
   * Never assume that a price returned by an API is using a given currency only
   * based on its country code. Always request a price with a specific currency
   * or use a currency associated with the price
   */
  readonly currencyCode?: string;

  constructor(languageCode: LanguageCode);
  constructor(
    languageCode: LanguageCode,
    regionCode: RegionCode,
    siteSlug: SiteSlug,
  );
  constructor(
    languageCode: LanguageCode,
    regionCode: RegionCode,
    siteSlug: SiteSlug,
    localizedMarketName: string,
    currencyCode: string,
  );
  constructor(
    languageCode: LanguageCode,
    regionCode: RegionCode,
    siteSlug: SiteSlug,
    localizedMarketName: string,
    currencyCode?: string,
  );
  constructor(
    languageCode: LanguageCode,
    regionCode?: RegionCode,
    siteSlug?: SiteSlug,
    localizedMarketName?: string,
    currencyCode?: string,
  ) {
    const marketName = regionCode && markets[regionCode];
    const [languageName, isRtl] = languages[languageCode];

    this.marketName = marketName;
    this.regionCode = regionCode;
    this.siteSlug = siteSlug;
    this.languageName = languageName;
    this.localizedMarketName = localizedMarketName ?? marketName;
    this.currencyCode = currencyCode;

    // Use defineProperty to make these instance properties and not a getter
    // on the prototype to:
    //
    //  - Make it possible to ...spread
    //  - Make the property enumerable
    Object.defineProperties(this, {
      href: {
        enumerable: true,
        get() {
          return siteSlug ? `/${siteSlug}` : '/';
        },
      },
      locale: {
        enumerable: true,
        get() {
          return getLocaleFromLanguageCodeAndRegionCode(
            languageCode,
            regionCode,
          );
        },
      },

      languageCode: {
        enumerable: true,
        get() {
          return languageCode.includes('-')
            ? languageCode.substring(0, languageCode.indexOf('-'))
            : languageCode;
        },
      },
      languageDirection: {
        enumerable: true,
        get() {
          return isRtl ? 'rtl' : 'ltr';
        },
      },
      multilingual: {
        enumerable: true,
        get() {
          return siteSlug && regionCode
            ? siteSlugByRegionCode[regionCode]?.length > 1
            : undefined;
        },
      },
      htmlLanguage: {
        enumerable: true,
        get() {
          const isOnlySiteWithLanguage =
            siteSlugByLanguageCode[languageCode].length === 1;
          if (
            isOnlySiteWithLanguage ||
            regionCode?.match(/\d+/) ||
            regionCode?.startsWith('XX')
          ) {
            return languageCode;
          }
          return this.locale;
        },
      },
      roadLengthUnit: {
        enumerable: true,
        get() {
          return (
            (regionCode && roadLengthUnitByRegion[regionCode]) ||
            roadLengthUnitByRegion['001']
          );
        },
      },
      localizedLanguageName: {
        enumerable: true,
        get() {
          return getLanguageDisplayName(
            this.locale,
            this.languageCode,
            this.languageName,
          );
        },
      },
    });
  }
}

type KnownMarketSite = Required<MarketSite>;

// If argument is verified to be a SiteSlug, returns a MarketSite with NonNullable `siteSlug` etc
export function getMarketSite<Slug extends SiteSlug>(
  siteSlugOrPathName: Slug,
): KnownMarketSite;
export function getMarketSite(siteSlugOrPathName?: string): MarketSite;
export function getMarketSite(siteSlugOrPathName?: SiteSlug | string) {
  const siteSlug = getSiteSlug(siteSlugOrPathName);
  if (!isValidSiteSlug(siteSlug)) {
    return new MarketSite('en');
  }
  const [regionCode, languageCode, localizedMarketName, currencyCode] =
    sites[siteSlug];
  return new MarketSite(
    languageCode,
    regionCode,
    siteSlug,
    localizedMarketName,
    currencyCode,
  );
}

export function getAllMarketSites() {
  return (Object.keys(sites) as SiteSlug[]).map((siteSlug) =>
    getMarketSite(siteSlug),
  );
}

export function getMarketSitesByRegion(regionCode: RegionCode) {
  if (typeof regionCode !== 'string') {
    throw new TypeError(
      `Invalid region code. Expected string, got ${typeof regionCode}`,
    );
  }
  const siteSlugs =
    siteSlugByRegionCode[regionCode.toUpperCase() as RegionCode];
  if (siteSlugs && siteSlugs.length > 0) {
    return siteSlugs.map((siteSlug) => getMarketSite(siteSlug));
  }
  return [];
}

export function getMarketSiteByRegionAndLanguage(
  regionCode: RegionCode,
  languageCode: LanguageCode,
) {
  return getMarketSitesByRegion(regionCode).find(
    (region) => region.languageCode === languageCode?.toLowerCase(),
  );
}
export function getMarketSiteByLocale(locale: string) {
  if (typeof locale !== 'string') {
    throw new TypeError(
      `Invalid locale. Expected string, got ${typeof locale}`,
    );
  }
  const siteSlug = siteSlugByLocale[locale];
  return getMarketSite(siteSlug);
}
