'use client';

import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getClientFlags, getUserAndClient } from './get-flags.client';
import type {
  CustomAttributes,
  FlagsProviderContext,
  FlagsProviderProps,
  Flags,
  GlobalDotcomFlags,
} from './flags.types';
import isEqual from 'lodash/isEqual';
import { globalClientIdByDeployEnv } from './flags.constants';
import { LDClient } from 'launchdarkly-js-client-sdk';

export const initialValue: FlagsProviderContext = {
  flags: {},
  globalFlags: {},
  updateAttributes: () => {},
  loading: true,
  client: null,
  globalClient: null,
};

const FlagsContext = React.createContext(initialValue);

export const FeatureFlagsProvider: FC<PropsWithChildren<FlagsProviderProps>> = (
  props,
) => {
  const context = useFeatureFlagsWithoutProvider(props);
  return (
    <FlagsContext.Provider value={context}>
      {props.children}
    </FlagsContext.Provider>
  );
};

/**
 * Load the feature flags without using the FeatureFlagsPovider..
 * This should only be used inside a singleton, eg. inside another provider.
 */
export const useFeatureFlagsWithoutProvider = ({
  clientName = 'unknown',
  customAttributes: initialAttributes,
  globalFlagsEnv,
  initialFlags,
  ldClientId,
  ldClientIdGlobal,
  ldUserKey,
  ldOptions,
  ldOptionsGlobal,
}: FlagsProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [currentAttributes, setAttributes] = useState({
    ...initialAttributes,
    clientName,
  });
  const previousAttributes = useRef<CustomAttributes | null>();
  const [client, setClient] = useState<LDClient | null | undefined>(null);
  const [globalClient, setGlobalClient] = useState<LDClient | null | undefined>(
    null,
  );
  const [flags, setFlags] = useState<Flags>(initialFlags?.flags || {});
  const [globalFlags, setGlobalFlags] = useState<GlobalDotcomFlags | Flags>(
    initialFlags?.globalFlags || {},
  );
  const globalId =
    ldClientIdGlobal ||
    (globalFlagsEnv && globalClientIdByDeployEnv[globalFlagsEnv]);

  const updateAttributes = useCallback(
    (attributes: Partial<CustomAttributes>) => {
      const updatedAttributes = {
        ...currentAttributes,
        ...attributes,
      };
      if (attributes && !isEqual(currentAttributes, updatedAttributes)) {
        setAttributes(updatedAttributes);
      }
    },
    [currentAttributes],
  );

  useEffect(() => {
    (async function initClients() {
      // only load once per change in custom attributes
      if (currentAttributes === previousAttributes.current) {
        if (previousAttributes.current) {
          setLoading(false);
        }
        return;
      }
      previousAttributes.current = currentAttributes;

      if (!initialFlags) {
        console.log(
          `[FlagsProvider] '${clientName}' client - no initial flags, using defaults`,
        );
      }

      if (!ldClientId && !globalId) {
        console.log(
          `[FlagsProvider] '${clientName}' client - missing Client ID`,
        );
        setLoading(false);
        return;
      }

      const { client, globalClient } = await getUserAndClient({
        customAttributes: currentAttributes,
        ldClientId,
        ldClientIdGlobal: globalId,
        ldUserKey,
        ldOptions,
        ldOptionsGlobal,
      });
      setClient(client);
      setGlobalClient(globalClient);

      const clientFlags = await getClientFlags({
        client,
        globalClient,
      });
      setFlags({
        ...initialFlags?.flags,
        ...clientFlags.flags,
      });
      setGlobalFlags({
        ...initialFlags?.globalFlags,
        ...clientFlags.globalFlags,
      });
      setLoading(false);
    })();
  }, [
    clientName,
    currentAttributes,
    globalId,
    initialFlags,
    ldClientId,
    ldUserKey,
    ldOptions,
    ldOptionsGlobal,
  ]);

  useEffect(() => {
    // check once, on load
    if (!initialAttributes) {
      console.warn(
        `[FlagsProvider] '${clientName}' client - missing initial attributes`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      updateAttributes,
      flags,
      globalFlags,
      loading,
      client,
      globalClient,
    }),
    [client, flags, globalClient, globalFlags, loading, updateAttributes],
  );
};

/**
 * Pass known flag types to get get a typed flags, eg.
 * ```ts
 * type AppFlags = { appFeature1: boolean, appFeature2: boolean };
 * type GlobalFlags = { globalFeature: boolean };
 * const { flags, globalFlags } = useFeatureFlag<AppFeature, GlobalFeature>();
 * ```
 */
export const useFeatureFlags = <F extends Flags = Flags>() => {
  const context = useContext(FlagsContext);

  return {
    ...context,
    flags: context.flags as F,
    globalFlags: context.globalFlags as GlobalDotcomFlags,
  };
};

export const useGlobalClient = () => {
  const context = useContext(FlagsContext);
  return context.globalClient;
};
