import { apmBase } from '@elastic/apm-rum';
import { DEPLOY_ENV } from 'src/constants/general';

export const logError = (errorMessage: string) => {
  if (DEPLOY_ENV !== 'prod' && process.env.NODE_ENV !== 'test') {
    console.error(errorMessage);
  }
  // Due to OpenTelemetry not supporting client-side error capturing, we use
  // Elastic APM RUM agent instead on the client.
  const error = new Error(errorMessage);
  apmBase.captureError(error);
};
