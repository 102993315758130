// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars */
import React, { ErrorInfo } from 'react';
import { logError } from 'src/utils/logError';
import { ErrorMessages } from '../constants/errorMessages';

export default class ErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(
    error: React.GetDerivedStateFromError<{}, { hasErrors: boolean }>,
  ) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(
      `${ErrorMessages.UNCAUGHT_ERROR}:  ${error} ${errorInfo.componentStack ? `with component trace: ${errorInfo.componentStack}` : ''} `,
    );
  }

  render() {
    //probably best to add a fallback UI.
    return this.props.children;
  }
}
