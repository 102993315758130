// lat/lng to distance. Haversine formula used by retailer api
export function getDistance(
  [lat1, lng1]: [number, number],
  [lat2, lng2]: [number, number],
  radius: number,
) {
  const dLat = degToRad(lat2 - lat1);
  const dLon = degToRad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(lat1)) *
      Math.cos(degToRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return radius * c;
}

const degToRad = (deg: number) => deg * (Math.PI / 180);
