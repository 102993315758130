import { getLocaleFromLanguageCodeAndRegionCode } from './getLocaleFromLanguageCodeAndRegionCode';
import { LanguageCode } from './languages';
import { RegionCode } from './markets';

export type MarketSiteData = Readonly<[RegionCode, LanguageCode]>;

/**
 * An object where the key is the site slug and the value is an array of
 *
 *  - Region Code defined in markets.ts
 *  - Language Code defined in languages.ts
 */
export const sites = {
  /**
   * Special Sales
   */
  intl: ['001', 'en', 'International'], // International
  master: ['XX', 'en', 'Master'], // Master copy, not exposed live

  /**
   * Regions/countries
   */
  al: ['AL', 'sq', 'Shqipëria', 'ALL'], // Albania
  ao: ['AO', 'pt', 'Angola', 'AOA'], // Angola
  ar: ['AR', 'es', 'Argentina', 'ARS'], // Argentina
  'hy-am': ['AM', 'hy', 'Hayastán', 'AMD'], // Armenia
  au: ['AU', 'en', 'Australia', 'AUD'], // Australia
  at: ['AT', 'de', 'Österreich', 'EUR'], // Austria
  az: ['AZ', 'az', 'Azərbaycan', 'AZN'], // Azerbaijan
  'ar-bh': ['BH', 'ar', 'البحرين', 'BHD'], // Bahrain - Arabic
  'en-bh': ['BH', 'en', 'Bahrain', 'BHD'], // Bahrain - English
  by: ['BY', 'be', 'Беларусь', 'BYN'], // Belarus
  'fr-be': ['BE', 'fr', 'Belgique', 'EUR'], // Belgium - French
  'nl-be': ['BE', 'nl', 'België', 'EUR'], // Belgium - Dutch
  ba: ['BA', 'bs', 'Bosnia I Hercegovína', 'BAM'], // Bosnia and Herzegovina
  bw: ['BW', 'en', 'Botswana', 'BWP'], // Botswana
  br: ['BR', 'pt', 'Brasil', 'BRL'], // Brazil
  bg: ['BG', 'bg', 'България', 'BGN'], // Bulgaria
  kh: ['KH', 'en', 'Cambodia', 'KHR'], // Cambodia
  'en-ca': ['CA', 'en', 'Canada', 'CAD'], // Canada - English
  'fr-ca': ['CA', 'fr', 'Canada', 'CAD'], // Canada - French
  cl: ['CL', 'es', 'Chile', 'CLF'], // Chile
  'zh-cn': ['CN', 'zh', '中国', 'CNY'], // China
  hk: ['HK', 'en', 'Hong Kong', 'HKD'], // Hong Kong, China
  tw: ['TW', 'zh', '台灣', 'TWD'], // Taiwan, China, Traditional Chinese
  co: ['CO', 'es', 'Colombia', 'COP'], // Colombia
  cr: ['CR', 'es', 'Costa Rica', 'CRC'], // Costa Rica
  hr: ['HR', 'hr', 'Hrvatska', 'HRK'], // Croatia
  cy: ['CY', 'en', 'Cyprus', 'EUR'], // Cyprus
  cz: ['CZ', 'cs', 'Česká republika', 'CZK'], // Czech Republic
  dk: ['DK', 'da', 'Danmark', 'DKK'], // Denmark
  do: ['DO', 'es', 'República Dominicana', 'DOP'], // Dominican Republic
  ec: ['EC', 'es', 'Ecuador', 'USD'], // Ecuador
  'ar-eg': ['EG', 'ar', 'مصر', 'EGP'], // Egypt - Arabic
  'en-eg': ['EG', 'en', 'Egypt', 'EGP'], // Egypt - English
  'et-ee': ['EE', 'et', 'Eesti', 'EUR'], // Estonia
  fi: ['FI', 'fi', 'Suomi', 'EUR'], // Finland
  fr: ['FR', 'fr', 'France', 'EUR'], // France
  ge: ['GE', 'ka', 'საქართველო', 'GEL'], // Georgia
  de: ['DE', 'de', 'Deutschland', 'EUR'], // Germany
  gr: ['GR', 'el', 'Ελλάς', 'EUR'], // Greece
  gt: ['GT', 'es', 'Guatemala', 'GTA'], // Guatemala
  hu: ['HU', 'hu', 'Magyarország', 'HUF'], // Hungary
  in: ['IN', 'en', 'India', 'INR'], // India
  id: ['ID', 'id', 'Indonesia', 'IDR'], // Indonesia
  'id-id': ['ID', 'id', 'Indonesia', 'IDR'], // Indonesia - Bahasa
  'en-id': ['ID', 'en', 'Indonesia', 'IDR'], // Indonesia - English
  it: ['IT', 'it', 'Italia', 'EUR'], // Italy
  is: ['IS', 'is', 'Ísland', 'ISK'], // Iceland
  ie: ['IE', 'en', 'Ireland', 'EUR'], // Ireland
  il: ['IL', 'he', 'ישראל', 'ILS'], // Israel
  jp: ['JP', 'ja', '日本', 'JPY'], // Japan
  'ar-jo': ['JO', 'ar', 'الأردن', 'JOD'], // Jordan - Arabic
  'en-jo': ['JO', 'en', 'Jordan', 'JOD'], // Jordan - English
  ke: ['KE', 'en', 'Kenya', 'KES'], // Kenya
  'ar-kw': ['KW', 'ar', 'دولة الكويت', 'KWD'], // Kuwait - Arabic
  'en-kw': ['KW', 'en', 'Kuwait', 'KWD'], // Kuwait - English,
  'lv-lv': ['LV', 'lv', 'Latvija', 'EUR'], // Latvia
  lb: ['LB', 'en', 'Lebanon', 'LBP'], // Lebanon
  'lt-lt': ['LT', 'lt', 'Lietuva', 'EUR'], // Lithuania
  lu: ['LU', 'fr', 'Luxembourg', 'EUR'], // Luxembourg
  mk: ['MK', 'mk', 'Северна Македонија', 'MKD'], // North Macedonia
  mt: ['MT', 'en', 'Malta', 'EUR'], // Malta
  my: ['MY', 'en', 'Malaysia', 'MYR'], // Malaysia
  mu: ['MU', 'en', 'Mauritius', 'MUR'], // Mauritius
  mx: ['MX', 'es', 'México', 'MXN'], // Mexico
  'ru-md': ['MD', 'ru', 'Молда́вия', 'MDL'], // Moldova - Russian
  'ro-md': ['MD', 'ro-MD', 'Republica Moldova', 'MDL'], // Moldova - Moldavian
  me: ['ME', 'cnr', 'Crna Gora', 'EUR'], // Montenegro
  ma: ['MA', 'fr', 'Morocco', 'MAD'], // Morocco
  mz: ['MZ', 'pt', 'Moçambique', 'MZN'], // Mozambique
  mm: ['MM', 'en', 'Myanmar', 'MMK'], // Myanmar
  na: ['NA', 'en', 'Namibia', 'NAD'], // Namibia
  nl: ['NL', 'nl', 'Nederlân', 'EUR'], // Netherlands
  nz: ['NZ', 'en', 'New Zealand', 'NZD'], // New Zealand
  no: ['NO', 'no', 'Norge', 'NOK'], // Norway
  'ar-om': ['OM', 'ar', 'عُمان', 'OMR'], // Oman - Arabic
  'en-om': ['OM', 'en', 'Oman', 'OMR'], // Oman - English
  pa: ['PA', 'es', 'Panamá', 'PAB'], // Panama
  py: ['PY', 'es', 'Paraguái', 'PYG'], // Paraguay
  pl: ['PL', 'pl', 'Polska', 'PLN'], // Poland
  pt: ['PT', 'pt', 'Portugal', 'EUR'], // Portugal
  pe: ['PE', 'es', 'Perú', 'PEN'], // Peru
  ph: ['PH', 'en', 'Philippines', 'PHP'], // Philippines
  pr: ['PR', 'es', 'Puerto Rico', 'USD'], // Puerto Rico
  'ar-qa': ['QA', 'ar', 'قطر', 'QAR'], // Qatar - Arabic
  'en-qa': ['QA', 'en', 'Qatar', 'QAR'], // Qatar - English
  ro: ['RO', 'ro', 'România', 'RON'], // Romania
  ru: ['RU', 'ru', 'Россия', 'RUB'], // Russia
  'ar-sa': ['SA', 'ar', 'المملكة العربية السعودية', 'SAR'], // Saudi Arabia - Arabic
  'en-sa': ['SA', 'en', 'Saudi Arabia', 'SAR'], // Saudi Arabia - English
  rs: ['RS', 'sr-Latn', 'Srbija', 'RSD'], // Serbia
  sg: ['SG', 'en', 'Singapore', 'SGD'], // Singapore
  sk: ['SK', 'sk', 'Slovensko', 'EUR'], // Slovakia
  si: ['SI', 'sl', 'Slovenija', 'EUR'], // Slovenia
  za: ['ZA', 'en', 'South Africa', 'ZAR'], // South Africa
  kr: ['KR', 'ko', '대한민국', 'KRW'], // Korea
  es: ['ES', 'es', 'España', 'EUR'], // Spain
  lk: ['LK', 'en', 'Sri Lanka', 'LKR'], // Sri Lanka
  se: ['SE', 'sv', 'Sverige', 'SEK'], // Sweden
  'fr-ch': ['CH', 'fr', 'Suisse', 'CHF'], // Switzerland - French
  'de-ch': ['CH', 'de', 'Schweiz', 'CHF'], // Switzerland - German
  'it-ch': ['CH', 'it', 'Svizzera', 'CHF'], // Switzerland - Italian
  'en-th': ['TH', 'en', 'Thailand', 'THB'], // Thailand - English
  'th-th': ['TH', 'th', 'ราชอาณาจักรไทย', 'THB'], // Thailand - Thai
  tr: ['TR', 'tr', 'Türkiye', 'TRY'], // Turkey
  'uk-ua': ['UA', 'uk', 'Україна', 'UAH'], // Ukraine
  'ar-ae': ['AE', 'ar', 'الإمارات العربيّة المتّحدة', 'AED'], // United Arab Emirates - Arabic
  'en-ae': ['AE', 'en', 'United Arab Emirates', 'AED'], // United Arab Emirates - English
  uk: ['GB', 'en', 'United Kingdom', 'GBP'], // United Kingdom
  us: ['US', 'en', 'United States', 'USD'], // United States - English
  'es-us': ['US', 'es', 'Estados Unidos', 'USD'], // United States - Spanish
  uy: ['UY', 'es', 'Uruguay', 'UYU'], // Uruguay
  'en-vn': ['VN', 'en', 'Vietnam', 'VND'], // Vietnam - English
  'vi-vn': ['VN', 'vi', 'Việt Nam', 'VND'], // Vietnam - Vietnamese
  zm: ['ZM', 'en', 'Zambia', 'ZMW'], // Zambia
  zw: ['ZW', 'en', 'Zimbabwe', 'ZWL'], // Zimbabwe
  tn: ['TN', 'fr', 'Tunisie', 'TND'], // Tunisia
  uz: ['UZ', 'ru', 'Узбекиста́н', 'UZS'], // Uzbekistan
  tt: ['TT', 'en', 'Trinidad and Tobago', 'TTD'], // Trinidad and Tobago
  xk: ['XK', 'sq', 'Kosovo', 'EUR'], // Kosovo
} as const;

export const siteSlugByRegionCode = {} as Record<RegionCode, SiteSlug[]>;
export const siteSlugByLanguageCode = {} as Record<LanguageCode, SiteSlug[]>;
export const siteSlugByLocale = {} as Record<string, SiteSlug>;

for (const [siteSlug, [regionCode, languageCode]] of Object.entries(
  sites,
) as Array<[SiteSlug, [RegionCode, LanguageCode, string, string]]>) {
  if (!siteSlugByRegionCode[regionCode]) {
    siteSlugByRegionCode[regionCode] = [];
  }
  siteSlugByRegionCode[regionCode].push(siteSlug);

  if (!siteSlugByLanguageCode[languageCode]) {
    siteSlugByLanguageCode[languageCode] = [];
  }
  siteSlugByLanguageCode[languageCode].push(siteSlug);
  siteSlugByLocale[
    getLocaleFromLanguageCodeAndRegionCode(languageCode, regionCode)
  ] = siteSlug;
}

export type SiteSlug = keyof typeof sites;
