/** Will return a new array with the prioritized retailer at the front.
 * If prioritized retailer is not found, returns provided array.
 */
export const getReorderedRetailers = <T>(
  retailers: T[],
  priorityParmaPartnerCode: string | undefined,
  compareValueFinder: (r: T) => string | undefined,
) => {
  if (!priorityParmaPartnerCode) {
    return retailers;
  }
  const priorityRetailer = retailers.find(
    (r) => compareValueFinder(r) === priorityParmaPartnerCode,
  );
  if (!priorityRetailer) {
    return retailers;
  }

  const orderedRetailers = retailers.filter(
    (r) => compareValueFinder(r) !== priorityParmaPartnerCode,
  );
  orderedRetailers.unshift(priorityRetailer);
  return orderedRetailers;
};
