import { useMemo } from 'react';
import { SiteSlug } from '@volvo-cars/market-sites';
import {
  AorRetailersError,
  AorRetailersResponse,
} from 'src/types/apiUtilTypes';
import { AOR_RETAILERS_ENDPOINT } from 'src/constants/gql';
import { formUrl } from 'src/utils/formUrl';
import { isValidLatitudeLongitude } from 'src/utils/validations';
import { logError } from 'src/utils/logError';
import { ErrorMessages } from 'src/constants/errorMessages';
import { useFeatureFlags } from './useFeatureFlags';
import { useFetch } from './useFetch';

type AorRetailersProps = {
  coordinates?: {
    longitude: number;
    latitude: number;
  };
  place_id?: string | undefined;
  sessiontoken: string | undefined;
  siteSlug: SiteSlug | undefined;
};

const useAorRetailers = ({
  coordinates,
  place_id,
  sessiontoken,
  siteSlug,
}: AorRetailersProps) => {
  if (place_id && coordinates) {
    logError(
      `${ErrorMessages.AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS}: both place_id ${place_id} and coordinates ${coordinates} are being sent`,
    );
  }

  const { useTerritoryDealer } = useFeatureFlags();

  const isValidCoordinates = useMemo(
    () => coordinates && isValidLatitudeLongitude(coordinates),
    [coordinates],
  );
  const url = formUrl(AOR_RETAILERS_ENDPOINT, {
    ...(isValidCoordinates ? coordinates : {}),
    place_id,
    sessiontoken,
    siteSlug,
  });

  const { data, isLoading, error } = useFetch<
    AorRetailersResponse,
    AorRetailersError
  >(
    useTerritoryDealer && (coordinates || place_id) ? url : null,

    [coordinates, place_id],
  );
  return {
    data: data?.data,
    isLoading,
    error,
  };
};

export default useAorRetailers;
