import { Global, Lead } from '@volvo-cars/tracking/dist/types';
import { StoreContextValue } from 'src/providers';
import { ErrorMessages } from 'src/constants/errorMessages';
import { parseFeatureFlags, RawFeatureFlags } from './parseFeatureFlags';
import { logError } from './logError';

// Returns a GA4-formatted string with the dealer's partnerId and/or parmaPartnerCode.
// If no ids are available, returns undefined
export const getDealerId = (
  retailer: StoreContextValue['selectedRetailer'],
): Lead['dealerId'] => {
  const hasId = !!(retailer?.partnerId || retailer?.parmaPartnerCode);
  return hasId
    ? `${retailer.partnerId ?? 'not-set'}|` +
        `${retailer.parmaPartnerCode ?? 'not-set'}`
    : undefined;
};

// Returns a string with all feature flag keys & values
export const featureFlagsToString = (
  flags?: RawFeatureFlags,
): Global['featureFlag'] => {
  if (!flags) {
    logError(ErrorMessages.FEATURE_FLAGS_NOT_PROVIDED);
    return;
  }
  const { feature } = parseFeatureFlags(flags);
  if (!feature) {
    return;
  }
  return Object.entries(feature)
    .filter(([, val]) => val !== undefined)
    .map(([key, val]) => `${key}=${val?.toString()}`)
    .join(',');
};
