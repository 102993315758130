import { Tracker, type TrackerOptions } from '@volvo-cars/tracking';

interface WebVitalsMetric {
  id: string;
  label?: string;
  name: string;
  startTime?: number;
  value: number;
}

const customMetric = 'Next.js custom metric';
const defaultLabel = 'web-vital';

// https://github.com/GoogleChrome/web-vitals#send-the-results-to-google-analytics
// https://nextjs.org/docs/advanced-features/measuring-performance
export function reportWebVitals({
  metrics: { id, label, name, value },
  additionalEventData,
  options,
}: {
  metrics: WebVitalsMetric;
  additionalEventData?: Record<string, any>;
  options?: TrackerOptions;
}) {
  const tracker = new Tracker(null, {
    ...options,
    ga3: {
      ...options?.ga3,
      eventCategory:
        label === defaultLabel || !label ? 'Web Vitals' : customMetric,
    },
  });

  tracker.webVitals({
    eventLabel: id,
    eventCategory:
      label === defaultLabel || !label ? 'web_vitals' : customMetric,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventAction: name,
    ...additionalEventData,
  });
}
