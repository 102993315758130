import { LanguageCode } from './languages';
import { RegionCode } from './markets';

export function getLocaleFromLanguageCodeAndRegionCode(
  languageCode: LanguageCode,
  regionCode?: RegionCode,
) {
  if (
    !regionCode ||
    // Already has region code
    languageCode.endsWith(`-${regionCode}`) ||
    // master is considered en
    regionCode === 'XX'
  ) {
    return languageCode;
  } else {
    return `${languageCode}-${regionCode}`;
  }
}
