import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { GetClientFlags, GetUserAndClientFlags } from './flags.types';
import { getAnonymousKey } from './get-anonymous-key';

const defaultLdOptions = {
  sendEvents: false,
  allAttributesPrivate: true,
};

const ldClients: Record<string, LaunchDarkly.LDClient | undefined> = {};
const ldGlobalClients: Record<string, LaunchDarkly.LDClient | undefined> = {};

export const getClientFlags = async ({
  client,
  globalClient,
}: GetClientFlags) => {
  const [flags, globalFlags] = await Promise.all([
    client?.allFlags(),
    globalClient?.allFlags(),
  ]);

  return { flags, globalFlags };
};

export const getUserAndClient = async ({
  ldClientId,
  ldClientIdGlobal,
  customAttributes,
  ldUserKey,
  ldOptions,
  ldOptionsGlobal,
}: GetUserAndClientFlags) => {
  const user: LaunchDarkly.LDContext = {
    kind: 'user',
    anonymous: true,
    key: ldUserKey || getAnonymousKey(),
    ssr: false,
    ...customAttributes,
  };

  const options = { ...defaultLdOptions, ...ldOptions };
  const globalOptions = { ...defaultLdOptions, ...ldOptionsGlobal };
  const [client, globalClient] = await Promise.all([
    ldClientId ? getClient(ldClientId, user, options) : null,
    ldClientIdGlobal
      ? getGlobalClient(ldClientIdGlobal, user, globalOptions)
      : null,
  ]);

  return { client, globalClient };
};

const getClient = async (
  clientId: string,
  context: LaunchDarkly.LDContext,
  ldOptions: LaunchDarkly.LDOptions,
) => {
  const ldClient = ldClients[clientId];
  if (ldClient) {
    // use identify to update the context
    await ldClient.identify(context);
    return ldClient;
  }
  ldClients[clientId] = await initialize({ clientId, context, ldOptions });
  return ldClients[clientId];
};

const getGlobalClient = async (
  clientId: string,
  context: LaunchDarkly.LDContext,
  ldOptions: LaunchDarkly.LDOptions,
) => {
  const ldGlobalClient = ldGlobalClients[clientId];
  if (ldGlobalClient) {
    // use identify to update flags according to new attributes
    await ldGlobalClient.identify(context);
    return ldGlobalClient;
  }
  ldGlobalClients[clientId] = await initialize({
    clientId,
    context,
    ldOptions,
  });
  return ldGlobalClients[clientId];
};

const initialize = async ({
  clientId,
  context,
  ldOptions,
}: {
  clientId: string;
  context: LaunchDarkly.LDContext;
  ldOptions: LaunchDarkly.LDOptions;
}) => {
  const client = LaunchDarkly.initialize(clientId, context, ldOptions);
  await client.waitForInitialization(5);
  return client;
};
