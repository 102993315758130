export function getElasticVariablesFromEnv({
  withApiKey,
}: {
  withApiKey?: boolean;
} = {}) {
  return {
    environment: process.env.ELASTIC_APM_ENVIRONMENT,
    logLevel: process.env.ELASTIC_APM_LOG_LEVEL,
    serverUrl: process.env.ELASTIC_APM_SERVER_URL,
    serviceName: process.env.ELASTIC_APM_SERVICE_NAME,
    serviceVersion: process.env.BUILD_SOURCEVERSION,
    serviceApiKey: withApiKey ? process.env.ELASTIC_APM_API_KEY : undefined,
  };
}
