import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';
import { type TrackerOptions } from '../Tracker';
import { reportWebVitals } from './reportWebVitals';

const webVitalFns = [onCLS, onFCP, onINP, onFID, onLCP, onTTFB];

export function measureWebVitals({
  additionalEventData,
  options,
}: {
  additionalEventData?: Record<string, any>;
  options?: TrackerOptions;
}) {
  webVitalFns.forEach((fn) =>
    fn((metrics) => reportWebVitals({ metrics, additionalEventData, options }))
  );
}
