import { useFeatureFlags as useFeatureFlagsBase } from '@vcc-www/feature-flags';
import {
  parseFeatureFlags,
  RawFeatureFlags,
} from 'src/utils/parseFeatureFlags';

export const useFeatureFlags = () => {
  const { flags, client } = useFeatureFlagsBase<RawFeatureFlags>();
  const parsed = parseFeatureFlags(flags);

  const getVariationDetail = (flag: string) => {
    return client?.variationDetail(flag);
  };

  return {
    ...parsed.feature,
    getVariationDetail,
  };
};
