import React from 'react';
import { NextComponentType, NextPageContext } from 'next';
import { AppInitialProps, AppProps, NextWebVitalsMetric } from 'next/app';
import { StyleRenderer } from 'vcc-ui';
import {
  getVariablesFromPublicConfig,
  registerBrowserInstrumentation,
} from '@vcc-package/opentelemetry/browser';
import { urlFromString } from '@vcc-www/volvo-cars-url';
import { getMarketSite } from '@volvo-cars/market-sites';
import { reportWebVitals as reportWebVitalsTracking } from '@volvo-cars/tracking/webVitals';
import { GTMSnippets } from '@vcc-www/third-party-scripts/gtm-nextjs';
import ErrorBoundary from 'src/components/ErrorBoundary';
import withProviders from 'src/hoc/withProviders';
import { RawFeatureFlags } from 'src/utils/parseFeatureFlags';
import '../styles/utilities.css';
import '../styles/global.css';
import { getPublicEnv } from 'src/public-env';

registerBrowserInstrumentation({
  ...getVariablesFromPublicConfig(getPublicEnv()),
});

export interface PageProps {
  featureFlags?: RawFeatureFlags;
  dealerPage?: { name?: string };
}

const App = ({
  Component,
  pageProps,
  renderer,
  router,
}: {
  Component: NextComponentType<NextPageContext, AppInitialProps, PageProps>;
  pageProps: AppProps['pageProps'] & PageProps;
  renderer: StyleRenderer;
  router: AppProps['router'];
}) => {
  return (
    <ErrorBoundary>
      <GTMSnippets />
      {withProviders(renderer)(Component)({ pageProps, router })}
    </ErrorBoundary>
  );
};
export default App;

export function reportWebVitals(metrics: NextWebVitalsMetric) {
  const { siteSlug, contentPath } = urlFromString(window.location.href);
  const { locale, marketName } = getMarketSite(siteSlug);
  return reportWebVitalsTracking({
    metrics,
    additionalEventData: {
      locale,
      market: marketName,
      pageName: contentPath,
      pageType: 'dealer locator',
    },
  });
}
