const LD_LOCAL_STORAGE_KEY = 'ld:$anonUserId';

export const getAnonymousKey = () => {
  const existing = localStorage.getItem(LD_LOCAL_STORAGE_KEY);
  if (existing) return existing;

  const key = crypto.randomUUID();
  localStorage.setItem(LD_LOCAL_STORAGE_KEY, key);
  return key;
};
