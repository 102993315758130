// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-misused-new */
import {
  isValidSiteSlug,
  SiteSlug,
  validateSiteSlug,
} from '@volvo-cars/market-sites';

const cdnHosts = [
  'www.volvocars.com',
  'www.volvocars.cn',
  'www.volvocars.com.cn',
  'qawww.volvocars.com',
  'testwww.volvocars.com',
];

const authoringHosts = [
  'authoring.volvocars.com',
  'testwww.authoring.volvocars.com',
  'qawww.authoring.volvocars.com',
];

export interface VolvoCarsUrl extends URL {
  new (url: string, base?: string | URL | VolvoCarsUrl): VolvoCarsUrl;
  /**
   * Pathname without double slashes and with normalized unicode.
   */
  normalizedPathname: string;

  /**
   * Pathname excluding market site slug and `/v/` prefix. E.g. `/cars/xc90` from `/us/v/cars/xc90`.
   */
  contentPath: string;

  /**
   * Market site slug prefix, e.g. `fr-ca` from `/fr-ca/cars/xc90`.
   */
  siteSlug?: SiteSlug;

  /**
   * Product specific slug prefix, e.g. `care-by-volvo` from `/de/care-by-volvo/angebot`.
   */
  productSlug?: string;

  careByVolvoCustomerType: 'b2c' | 'b2b' | null;

  /**
   * Is the URL behind a CDN (e.g. www.volvocars.com, qawww.volvocars.com or testwww.volvocars.com).
   */
  isCdnUrl: boolean;

  /**
   * Is the URL an authoring environment (*authoring.volvocars.com).
   */
  isAuthoringUrl: boolean;
}

const defaultBaseUrl =
  typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : 'https://www.volvocars.com';

function getFirstSegment(pathname: string) {
  const pathSegments = pathname.split('/');
  if (pathSegments.length > 1) {
    return pathSegments[1];
  }
}

export function urlFromString(
  urlOrPath: string,
  baseUrl: string | URL | VolvoCarsUrl = defaultBaseUrl,
): VolvoCarsUrl {
  let url;
  if (urlOrPath.startsWith('http')) {
    url = new URL(urlOrPath);
  } else {
    url = new URL(urlOrPath, baseUrl);
  }
  Object.defineProperties(url, VolvoCarsUrlProto);
  return url as VolvoCarsUrl;
}

// Old school inheritance because of transpilation issues with `extends URL`
// Can be switched to `class VolvoCarsUrl extends URL` when Configurator Babel
// loader is switched to shared loader
const VolvoCarsUrlProto: PropertyDescriptorMap & ThisType<VolvoCarsUrl> = {
  normalizedPathname: {
    get() {
      // Remove multiple slashes
      let pathname = this.pathname.replace(/\/+/g, '/').replace(/\/$/, '');
      pathname = pathname.toLowerCase();
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
      // Not supported in IE11
      return typeof pathname.normalize === 'function'
        ? pathname.normalize()
        : pathname;
    },
  },
  careByVolvoCustomerType: {
    get() {
      if (this.productSlug !== 'care-by-volvo') {
        return null;
      }
      return this.contentPath.startsWith('/care-by-volvo/business')
        ? 'b2b'
        : 'b2c';
    },
  },
  contentPath: {
    get() {
      // Remove /{siteSlug}/
      const contentPath = this.normalizedPathname.replace(
        new RegExp(`^/${this.siteSlug}(/|$)`),
        '/',
      );
      // Remove DotCom /v/ prefix
      return contentPath.replace(/^\/v(\/|$)/, '/');
    },
  },
  productSlug: {
    get() {
      return getFirstSegment(this.contentPath);
    },
  },
  isCdnUrl: {
    get() {
      // testwww qawww and www subdomains
      return cdnHosts.includes(this.hostname);
    },
  },
  isAuthoringUrl: {
    get() {
      return authoringHosts.includes(this.hostname);
    },
  },
  siteSlug: {
    get() {
      let siteSlug;
      if (this.searchParams.has('sc_site')) {
        siteSlug = this.searchParams
          .get('sc_site')
          ?.replace(/['" ]+/g, '') as SiteSlug;
      } else {
        siteSlug = getFirstSegment(this.normalizedPathname);
      }
      if (typeof siteSlug === 'string') {
        siteSlug = siteSlug.toLowerCase();
      }
      if (isValidSiteSlug(siteSlug)) {
        return siteSlug;
      }
    },
    set(newSiteSlug) {
      validateSiteSlug(newSiteSlug);
      if (this.siteSlug) {
        this.pathname = this.pathname.replace(
          new RegExp(`^(/+)${this.siteSlug}(/|$)`),
          `$1${newSiteSlug}$2`,
        );
      } else {
        this.pathname = `/${newSiteSlug}${this.pathname}`;
      }
    },
  },
};
