import { RegionCode } from '.';

export type RoadLengthUnit = 'mile' | 'kilometer';

/**
 * Road length units by region. International (001) is used as the default.
 */
export const roadLengthUnitByRegion: Partial<
  Record<RegionCode, RoadLengthUnit>
> & {
  '001': RoadLengthUnit;
} = {
  '001': 'kilometer',
  US: 'mile',
  GB: 'mile',
} as const;
