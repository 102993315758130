export function getSiteSlug(siteSlugOrPathname?: string) {
  let siteSlug = siteSlugOrPathname;
  if (typeof siteSlug !== 'string') {
    return siteSlug;
  }
  if (siteSlug.startsWith('/')) {
    // Remove multiple slashes
    const urlParts = siteSlug.replace(/\/+/g, '/').split('/');
    if (urlParts.length > 1) {
      siteSlug = urlParts[1];
    }
  }
  return siteSlug.toLowerCase();
}
