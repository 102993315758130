import { useMemo } from 'react';
import {
  PlaceCoordinatesError,
  PlaceCoordinatesResponse,
} from 'src/types/apiUtilTypes';
import { PLACE_COORDINATES_ENDPOINT } from 'src/constants/gql';
import { formUrl } from 'src/utils/formUrl';
import { isValidLatitudeLongitude } from 'src/utils/validations';
import { useFetch } from './useFetch';

type UseCoordinatesProps = {
  coordinates?: {
    longitude: number;
    latitude: number;
  };
  place_id?: string | undefined;
  sessiontoken: string | undefined;
};

const useCoordinates = ({
  coordinates: initialCoordinates,
  place_id,
  sessiontoken,
}: UseCoordinatesProps) => {
  const url = formUrl(PLACE_COORDINATES_ENDPOINT, {
    place_id,
    sessiontoken,
  });
  const isValidCoordinates = useMemo(
    () => initialCoordinates && isValidLatitudeLongitude(initialCoordinates),
    [initialCoordinates],
  );

  const { data, isLoading, error } = useFetch<
    PlaceCoordinatesResponse,
    PlaceCoordinatesError
  >(!isValidCoordinates && place_id ? url : null, [
    place_id,
    isValidCoordinates,
  ]);

  const coordinates = useMemo(
    () => initialCoordinates || data?.data?.coordinates,
    [initialCoordinates, data?.data?.coordinates],
  );
  return {
    coordinates,
    isLoading,
    error,
  };
};

export default useCoordinates;
