import { StyleRendererConfig } from 'vcc-ui';
import { createRenderer } from '@vcc-www/global-style-provider';

export const getStyleRenderer = (config?: StyleRendererConfig) => {
  const renderer = createRenderer(config);
  renderer.renderStatic({ margin: 0 }, 'body');
  renderer.renderStatic(`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    `);
  renderer.renderStatic(
    {
      ['-webkit-font-smoothing' as string]: 'auto',
    },
    'a, button',
  );

  return renderer;
};

export default getStyleRenderer;
