import { useEffect } from 'react';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { AorRetailer, Retailer } from 'src/types/retailer';
import {
  DIPLOMAT_RETAILERS_ENDPOINT,
  RETAILER_LOCATIONS_ENDPOINT,
} from 'src/constants/gql';
import { StoreContextValue } from 'src/providers';
import { AutocompleteResponseError } from 'src/api-helpers/google-maps-api';
import { formUrl } from 'src/utils/formUrl';
import { CapabilityGroups } from 'src/types/retailerCapabilities';
import { CapabilityType } from 'src/gql/graphql';
import { logError } from 'src/utils/logError';
import { ErrorMessages } from 'src/constants/errorMessages';
import { useFetch } from '.';
import { useSelectorSettings } from './useStore';

export type RetailerResponse = {
  data?: Retailer[];
  aorRetailers?: AorRetailer[];
  error?: string;
  status: number;
};

export function useFetchRetailers({
  sessiontoken,
  refreshSessiontoken,
  initialRetailers,
}: {
  sessiontoken: StoreContextValue['sessiontoken'];
  refreshSessiontoken: any;
  initialRetailers: boolean;
}) {
  if (!initialRetailers) {
    logError(ErrorMessages.NO_INITIAL_RETAILERS);
  }
  const { regionCode, siteSlug } = useCurrentMarketSite();

  const selectorSettings = useSelectorSettings();
  const capabilities_array = selectorSettings.useSelector
    ? [CapabilityType.PreOrder]
    : [
        ...CapabilityGroups['sales'],
        ...CapabilityGroups['service'],
        ...CapabilityGroups['used car sales'],
      ];
  const isIntl = regionCode === '001';

  const url = isIntl
    ? formUrl(DIPLOMAT_RETAILERS_ENDPOINT, {
        siteSlug,
        sessiontoken,
        limit: 20,
        within: 300,
      })
    : formUrl(RETAILER_LOCATIONS_ENDPOINT, {
        siteSlug,
        sessiontoken,
        limit: 20,
        capabilities: capabilities_array,
        within: 300,
      });

  const {
    data: freshRetailers,
    isLoading,
    error,
  } = useFetch<RetailerResponse, AutocompleteResponseError>(
    !initialRetailers ? url : null,
    [initialRetailers],
  );
  useEffect(() => {
    freshRetailers?.data && refreshSessiontoken && refreshSessiontoken();
  }, [refreshSessiontoken, freshRetailers?.data]);

  return {
    data: freshRetailers?.data || null,
    isLoading,
    error,
  };
}
