import React, { createContext } from 'react';
import { StoreContextValue } from './StoreProvider';

export type SelectorSettings = {
  useSelector: boolean;
  onRetailerSelect: (retailer: StoreContextValue['selectedRetailer']) => void;
};

export const SelectorContext = createContext<SelectorSettings>({
  useSelector: false,
  onRetailerSelect: () => {},
});

type SelectorProviderProps = React.PropsWithChildren<{
  initialSettings: SelectorSettings;
}>;
export const SelectorProvider = ({
  initialSettings,
  children,
}: SelectorProviderProps): JSX.Element => {
  return (
    <SelectorContext.Provider value={initialSettings}>
      {children}
    </SelectorContext.Provider>
  );
};
