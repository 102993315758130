export const getLanguageDisplayName = (
  displayLocale: string | undefined,
  languageOrLocaleToTranslate: string | undefined,
  fallback: string | undefined,
) => {
  if (!displayLocale || !languageOrLocaleToTranslate) return fallback;
  try {
    const languageNames = new Intl.DisplayNames([displayLocale], {
      type: 'language',
    });
    return languageNames.of(languageOrLocaleToTranslate) || fallback;
  } catch (error) {
    return fallback;
  }
};
