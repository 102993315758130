import React from 'react';

declare global {
  interface Window {
    __PUBLIC_ENV?: Record<string, any>;
  }
}

/**
 * @param variables An object containing environment variables to expose to the browser
 * @warning Pay extra attention to any properties added here as they will be exposed to the browser
 * @example
 * export const { PublicEnv, getPublicEnv } = createPublicEnv({
 *   deployEnv: process.env.DEPLOY_ENV || 'test',
 *   isPreview: process.env.IS_PREVIEW,
});
 */
export function createPublicEnv<T = Record<string, any>>(variables: T) {
  return {
    /**
     * @returns exposed environment variables object  both server and client-side
     */
    getPublicEnv(): T {
      if (typeof window === 'undefined') return variables;
      if (!('__PUBLIC_ENV' in window)) {
        console.error(
          '"__PUBLIC_ENV" variable is expected to be available on Window; "<PublicEnv />", "renderPublicEnv()" or "mockPublicEnv()" can help with that',
        );
      }
      return ((window.__PUBLIC_ENV as unknown) || {}) as T;
    },
    /**
     * A React component that exposes environment variables to the client through a global variable.
     *
     * Render at the root of your application and only _server-side_
     * @throws When attempting to render client-side.
     */
    PublicEnv() {
      return (
        <script
          dangerouslySetInnerHTML={{
            __html: `window.__PUBLIC_ENV = ${JSON.stringify(variables)}`,
          }}
        />
      );
    },
  };
}
