const globalDevClientId = '642d1aa080e04913b3155888';
const globalProdClientId = '642d1a3767be3b13b8211724';

export const globalClientIdByDeployEnv = {
  dev: globalDevClientId,
  development: globalDevClientId,
  test: '642d1a3767be3b13b8211723',
  qa: '642d1a866e45a513113a3bfc',
  prod: globalProdClientId,
  production: globalProdClientId,
};
