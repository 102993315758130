import { CapabilityType } from 'src/gql/graphql';

export const ALL_CAPABILITIES = [
  'sales',
  'service',
  'collision center',
  'selekt',
  'diplomat',
  'test drive',
  'used car sales',
] as const;

export type Capability = (typeof ALL_CAPABILITIES)[number];

export const CapabilityGroups: Record<Capability, CapabilityType[]> = {
  sales: [CapabilityType.NewCarSales], // Todo rename
  'used car sales': [
    CapabilityType.UsedCarSales,
    CapabilityType.VolvoSelektMember,
  ],
  service: [CapabilityType.Workshop],
  'collision center': [CapabilityType.CertifiedDamageRepairWorkshop],
  selekt: [CapabilityType.VolvoSelektMember], // todo remove from available
  diplomat: [CapabilityType.Diplomat],
  'test drive': [CapabilityType.TestDriveBooking],
};
