export type Maybe<T> = T | null;
type InputMaybe<T> = Maybe<T>;
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Long: any;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  addressLine4?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  countryName?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  languageCode: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

type AdministrativeAddress = {
  __typename?: 'AdministrativeAddress';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export enum Brand {
  Pol = 'POL',
  Vcc = 'VCC',
}

export type Capability = {
  __typename?: 'Capability';
  b2BEmail?: Maybe<Scalars['String']>;
  b2BFax?: Maybe<Scalars['String']>;
  b2BPhone?: Maybe<Scalars['String']>;
  b2CEmail?: Maybe<Scalars['String']>;
  b2CPhone?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  escalatedAlertEmail?: Maybe<Scalars['String']>;
  name?: Maybe<CapabilityType>;
  newAlertEmail?: Maybe<Scalars['String']>;
  openingHours?: Maybe<OpeningHours>;
  overdueAlertEmail?: Maybe<Scalars['String']>;
  partsECommerceSales?: Maybe<Scalars['Boolean']>;
  testDrivePadding?: Maybe<Scalars['Int']>;
  testDriveSlotSize?: Maybe<Scalars['Int']>;
};

export enum CapabilityType {
  /** A Retailer that offers damage repair services */
  BodyShop = 'body_shop',
  /** US Pilot - Car pipeline view */
  CarPipelineView = 'car_pipeline_view',
  /** A Retailer that offers car rental services */
  CarRental = 'car_rental',
  /** A Retailer that offers and is certified for damage repair services */
  CertifiedDamageRepairWorkshop = 'certified_damage_repair_workshop',
  /** A Retailer that offers charging outlets for electric vehicles  within the premises */
  ChargingStation = 'charging_station',
  /** This is used by markets to indicate the retailer offers CbV subscription/sales */
  DigitalCommerce = 'digital_commerce',
  /** A Retailer that handles handover of the Digital Commerce Products */
  DigitalCommerceProducts = 'digital_commerce_products',
  /** A Retailer having enabled the VCC solution for digital service booking for Volvo */
  DigitalWorkshopBooking = 'digital_workshop_booking',
  /** A Retailer that is certified to handle Diplomat sales and is capable to fulfiil official and international formalities */
  Diplomat = 'diplomat',
  /** A Retailer that executes repair and maintenance jobs with and without pre-booking */
  DropInService = 'drop_in_service',
  /** Electric car */
  ElectricCar = 'electric_car',
  /** A Retailer that handles corporate, major account and fleet sales */
  FleetSales = 'fleet_sales',
  /** government_test */
  GovernmentTest = 'government_test',
  /** A Retailer that offers Insurance services */
  Insurance = 'insurance',
  /** legal_invoice */
  LegalInvoice = 'legal_invoice',
  /** main */
  Main = 'main',
  /** A Retailer that is certified to handle sales to Military personnel and is capable to fulfiil official and international formalities */
  Military = 'military',
  /** A Retailer that holds a Main Contract and is authorised for New Car Sales */
  NewCarSales = 'new_car_sales',
  /** Online sales */
  OnlineSales = 'online_sales',
  /** A Retailer that holds a Main Contract or Authorised Repairer Contract and do parts sales over the counter */
  Parts = 'parts',
  /** A partner who are contracted to perform PDI/PDS services on Polestar Vehicles */
  PolPdiPdsService = 'pol_PDI_PDS_service',
  /** A Retailer having enabled the VCC solution for digital service booking for Polestar */
  PolDigitalWorkshopBooking = 'pol_digital_workshop_booking',
  /** A partner who operates a dedicated Polestar Handover Center */
  PolHandOverCenter = 'pol_hand_over_center',
  /** A partner who performs delivery of Polestar vehicles in existing Volvo (VRE) facilities */
  PolHandOverPoint = 'pol_hand_over_point',
  /** A partner who perform any version of Polestar's range of home delivery options (plated, covered etc.) */
  PolHomeDelivery = 'pol_home_delivery',
  /** A service partner who have signed the Addendum agreement to the Volvo dealer agreements related to Pick up & delivery services in relation to above */
  PolPickupAndDelivery = 'pol_pickup_and_delivery',
  /** A location where leasing or customers vehicles can be returned to */
  PolReturnPoint = 'pol_return_point',
  /** A service partner who have signed the Addendum agreement to the Volvo dealer agreements related to Service Contracts or included scheduled maintenance */
  PolServiceContract = 'pol_service_contract',
  /** A partner who operates a Polestar Space- i.e Polestar's unique retail environment */
  PolSpace = 'pol_space',
  /** A service partner who have signed the Addendum agreement to the Volvo dealer agreements related to base repairer services for Polestar vehicles such as parts ordering and warranty claims */
  PolWarrantyAndRepair = 'pol_warranty_and_repair',
  /** A partner who performs services related to tires and Winter wheels including wheel storage */
  PolWheelChangeAndStorage = 'pol_wheel_change_and_storage',
  /** Pre-Order */
  PreOrder = 'pre_order',
  /** A Retailer that operates a Shop for various goods like merchandise, etc within the premises */
  Shop = 'shop',
  /** A Retailer who takes in online requests for test drive from the customers who would visit the partner location */
  TestDriveBooking = 'test_drive_booking',
  /** A Retailer who takes in online requests for test drive from the customers, wherein the retailer delivers a test drive car to the consumer at their location */
  TestDrivePickupAndDelivery = 'test_drive_pickup_and_delivery',
  /** time_booking */
  TimeBooking = 'time_booking',
  /** A Retailer that executes the sales of used cars */
  UsedCarSales = 'used_car_sales',
  /** A Retailer that is certified for Volvo Personal Service */
  VolvoPersonalService = 'volvo_personal_service',
  /** A Retailer that fulfils the latest architectural VRE facility standards in the premises */
  VolvoRetailExperience = 'volvo_retail_experience',
  /** A Retailer that is certified and executes the sales of used cars within the Volvo Selekt Program */
  VolvoSelektMember = 'volvo_selekt_member',
  /** A Retailer that holds a Main Contract or Authorised Repairer Contract and is authorised for Repair and Maintenance works */
  Workshop = 'workshop',
}

type Description = {
  __typename?: 'Description';
  aboutUs?: Maybe<Scalars['String']>;
  languageCode: Scalars['String'];
  promotionText?: Maybe<Scalars['String']>;
  tagLine?: Maybe<Scalars['String']>;
};

type DistributionPoint = {
  __typename?: 'DistributionPoint';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** The code of the destination location. It typically follows the convention <2 character country code><3 character city code><4 digit numeric> */
  code: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  /** The description/name of the destination code. */
  description: Scalars['String'];
  /** Indicates whether or not the location is set as default. */
  isDefault?: Maybe<Scalars['Boolean']>;
  isSalesCompanyStockLocation?: Maybe<Scalars['Boolean']>;
  /** Indicates whether or not the location is set as secondary default. */
  isSecondaryDefault?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
};

enum FacilityType {
  AuthorizedRepairer = 'AUTHORIZED_REPAIRER',
  Full = 'FULL',
  SatelliteServicing = 'SATELLITE_SERVICING',
  SatelliteServicingAndDelivery = 'SATELLITE_SERVICING_AND_DELIVERY',
  SatelliteServicingAndSelekt = 'SATELLITE_SERVICING_AND_SELEKT',
  SatelliteServicingDeliveryAndSelekt = 'SATELLITE_SERVICING_DELIVERY_AND_SELEKT',
}

export type GetPartnerOrganizationalUnitsQueryFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Brand>;
  capabilities?: InputMaybe<Array<CapabilityType>>;
  countryCode?: InputMaybe<Scalars['String']>;
  deliveringPartner?: InputMaybe<Scalars['Boolean']>;
  distributionPointCode?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  excludeInactiveFacilities?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  groupKey?: InputMaybe<Scalars['String']>;
  groupValue?: InputMaybe<Scalars['String']>;
  hierarchyLevel1?: InputMaybe<Scalars['String']>;
  hierarchyLevel2?: InputMaybe<Scalars['String']>;
  hierarchyType?: InputMaybe<Scalars['String']>;
  includeTerminated?: Scalars['Boolean'];
  market?: InputMaybe<Scalars['String']>;
  orderingPartner?: InputMaybe<Scalars['Boolean']>;
  parmaPartnerCode?: InputMaybe<Scalars['String']>;
  partsECommerceSales?: InputMaybe<Scalars['Boolean']>;
  roleCode?: InputMaybe<RoleCode>;
  /** Filters partner organizational units based on a territory type. Requires territoryZipCode and market filters to be provided in combination with this filter */
  territoryType?: InputMaybe<TerritoryType>;
  /** Filters partner organizational units responsible for a territory zip code. Requires market to be provided in combination with this filter */
  territoryZipCode?: InputMaybe<Scalars['String']>;
  updatedAfter?: InputMaybe<Scalars['String']>;
  visibleToPublic?: InputMaybe<Scalars['Boolean']>;
};

export type GetPartnersByLocationQueryFilterInput = {
  brand?: InputMaybe<Brand>;
  capabilities?: InputMaybe<Array<CapabilityType>>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  market: Scalars['String'];
};

type Group = {
  __typename?: 'Group';
  /** The type of group. */
  key: Scalars['String'];
  /** The name of the group the retailer belongs to for a given type of group. */
  value: Scalars['String'];
};

type Hierarchy = {
  __typename?: 'Hierarchy';
  /** The value of the parent node under a hierarchy type. A retailer can be linked to either a level 1 or level 2 node. */
  level1: Scalars['String'];
  /** The value of the child node under a hierarchy type. Level 2 node is optional. */
  level2?: Maybe<Scalars['String']>;
  /** The type of hierarchy, geographical or logical. */
  type: Scalars['String'];
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  friday: Array<TimeInterval>;
  monday: Array<TimeInterval>;
  saturday: Array<TimeInterval>;
  specialDates: Array<SpecialDate>;
  sunday: Array<TimeInterval>;
  thursday: Array<TimeInterval>;
  tuesday: Array<TimeInterval>;
  wednesday: Array<TimeInterval>;
};

type OrderingPartner = {
  __typename?: 'OrderingPartner';
  /** Flag to denote the default car ordering partner for a delivering partner. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** The partner id of the  car ordering partner for a delivering partner. */
  partnerId: Scalars['String'];
};

/** Information about pagination in a connection. */
type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

type PartnerLegalEntity = {
  __typename?: 'PartnerLegalEntity';
  authorizedCEO?: Maybe<Scalars['String']>;
  authorizedRepresentative?: Maybe<Scalars['String']>;
  commercialRegister?: Maybe<Scalars['String']>;
  commercialRegisterNo?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  insuranceIndex?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  locationOfRegistration?: Maybe<Scalars['String']>;
  salesTaxId?: Maybe<Scalars['String']>;
  tradeRegistration?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type PartnerOrganizationalUnit = {
  __typename?: 'PartnerOrganizationalUnit';
  /** Indicates if the retailer is active or not. */
  active: Scalars['Boolean'];
  /** The administrative mailing addresses of a partner organizational unit */
  administrativeMailingAddresses?: Maybe<Array<AdministrativeAddress>>;
  /** Indicates what brand the distributor does the business with. */
  brand: Brand;
  /** The distributor id that gets invoiced. */
  buyerCode?: Maybe<Scalars['String']>;
  /** Indicates whether or not the partner is on commercial delivery hold. */
  commercialDeliveryHoldIndicator?: Maybe<Scalars['Boolean']>;
  /** A partner that delivers a car to a customer but is not able to place an order in nVista i.e., it is a non-car ordering partner. */
  deliveringPartner: Scalars['Boolean'];
  /** The address locations where the car from the factory is delivered. These locations are managed and maintained by the OBL team. */
  distributionPoints?: Maybe<Array<DistributionPoint>>;
  /** The id of the distributor the retailer belongs to. */
  distributorId: Scalars['String'];
  districtManagerEmail?: Maybe<Scalars['String']>;
  /** The different groups a market has created for different business, reporting and analysis purposes. */
  groups?: Maybe<Array<Group>>;
  /** The different hierarchies a market has created for different business, reporting and analysis purposes. */
  hierarchies?: Maybe<Array<Hierarchy>>;
  localRetailerCode?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  managerDeputy1?: Maybe<Scalars['String']>;
  managerDeputy2?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  /** Indicates whether or not a distributer is on market hold. */
  marketHoldIndicator?: Maybe<Scalars['Boolean']>;
  /** The distributor id that order the cars for the retailers. */
  ordererCode?: Maybe<Scalars['String']>;
  /** Indicates if the retailer can create a car order in VISTA. */
  orderingPartner: Scalars['Boolean'];
  /** The list of car ordering partners that place an order on behalf of a delivering partner. */
  orderingPartners?: Maybe<Array<OrderingPartner>>;
  /** The VCC partner id that is used in VCC systems. Format: 3/6XX00000. XX = market code. 0000= CIS id/District number. */
  partnerId: Scalars['String'];
  /** The organisation details of the partner doing business with Volvo cars. */
  partnerLegalEntity: PartnerLegalEntity;
  /** The physical address(s) of the partner. */
  partnerOrganizationalUnitFacilities: Array<PartnerOrganizationalUnitFacility>;
  partnerOrganizationalUnitNames: Array<PartnerOrganizationalUnitName>;
  /** The id for a partner that is represented in QW90 application. */
  qw90Code?: Maybe<Scalars['String']>;
  /** Indicates what kind of role the retailer acts as like Authorized retailer, Administrative account, Studio, PopUp store, Fleet leads etc. */
  roleCode: RoleCode;
  sapParmaCode?: Maybe<Scalars['String']>;
  /** The date on which the partner was terminated. */
  terminatedDate?: Maybe<Scalars['String']>;
  /** Indicates if the partner is terminated. Applicable when no open orders exists. */
  terminatedIndicator?: Maybe<Scalars['Boolean']>;
  /** A code which identifies the Sub Division of specification to manufacturing that is required for an order/buyer combination. */
  vccProgramMarket?: Maybe<Scalars['String']>;
  /** Indicates if the retailer delivers any services to a general consumer and to be shown on volvocars.com. */
  visibleToPublic: Scalars['Boolean'];
};

export type PartnerOrganizationalUnitFacility = {
  __typename?: 'PartnerOrganizationalUnitFacility';
  /** Indicates if the partner's facility/site is active or not */
  active: Scalars['Boolean'];
  /** The visiting address of the partner site */
  addresses: Array<Address>;
  capabilities: Array<Capability>;
  descriptions?: Maybe<Array<Description>>;
  exclusiveShowroom?: Maybe<Scalars['Boolean']>;
  exclusiveSite?: Maybe<Scalars['Boolean']>;
  facilityId: Scalars['String'];
  facilityName?: Maybe<Scalars['String']>;
  facilityType?: Maybe<FacilityType>;
  /** Email id of the partner site that is used for general queries */
  generalContactEmail?: Maybe<Scalars['String']>;
  internalContactEmail?: Maybe<Scalars['String']>;
  /** GPSX coordinates of the partner site in decimal format */
  latitude?: Maybe<Scalars['String']>;
  /** GPSY coordinates of the partner site in decimal format */
  longitude?: Maybe<Scalars['String']>;
  /** The six digit numeric code that is registered in the PARMA application. */
  parmaCode?: Maybe<Scalars['Long']>;
  /** The five character alphanumeric code that is registered in the PARMA application. */
  parmaPartnerCode?: Maybe<Scalars['String']>;
  /** Phone number of the partner site that is used for general queries */
  phone?: Maybe<Scalars['String']>;
  polPulsId?: Maybe<PulsId>;
  timeZone?: Maybe<Scalars['String']>;
  /** The website urls for the different businesses of the partner. */
  urls?: Maybe<Array<Urls>>;
  vccPulsId?: Maybe<PulsId>;
};

type PartnerOrganizationalUnitFacilityLocation = {
  __typename?: 'PartnerOrganizationalUnitFacilityLocation';
  brand: Brand;
  distanceFromPointKm?: Maybe<Scalars['Float']>;
  distanceFromPointMiles?: Maybe<Scalars['Float']>;
  isDistanceByRoad?: Maybe<Scalars['Boolean']>;
  localRetailerCode: Scalars['String'];
  partnerId: Scalars['String'];
  partnerOrganizationalUnitFacility: PartnerOrganizationalUnitFacility;
  partnerOrganizationalUnitNames: Array<PartnerOrganizationalUnitName>;
};

type PartnerOrganizationalUnitName = {
  __typename?: 'PartnerOrganizationalUnitName';
  /** The 2-character ISO language code */
  languageCode: Scalars['String'];
  /** The name of the partner in the given language */
  name: Scalars['String'];
};

/** A connection to a list of items. */
type PartnerOrganizationalUnitsConnection = {
  __typename?: 'PartnerOrganizationalUnitsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PartnerOrganizationalUnitsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PartnerOrganizationalUnit>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
type PartnerOrganizationalUnitsEdge = {
  __typename?: 'PartnerOrganizationalUnitsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PartnerOrganizationalUnit;
};

type PulsId = {
  __typename?: 'PulsId';
  customerNr?: Maybe<Scalars['String']>;
  districtNr: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  partnerOrganizationalUnit?: Maybe<PartnerOrganizationalUnit>;
  /** Returns active and visible to public partner organizational unit facilities based on location */
  partnerOrganizationalUnitFacilityByLocation?: Maybe<ResourceResponseOfPartnerOrganizationalUnitFacilityLocation>;
  partnerOrganizationalUnits?: Maybe<PartnerOrganizationalUnitsConnection>;
};

export type ResourceResponseOfPartnerOrganizationalUnitFacilityLocation = {
  __typename?: 'ResourceResponseOfPartnerOrganizationalUnitFacilityLocation';
  items: Array<PartnerOrganizationalUnitFacilityLocation>;
  numberOfObjects: Scalars['Int'];
};

enum RoleCode {
  /** Partner codes for a retail partner with a contract only for a workshop. */
  AuthorizedRepairer = 'AUTHORIZED_REPAIRER',
  /** Partner codes for a retail partner with a contract for sales and workshop. */
  AuthRtlr = 'AUTH_RTLR',
  /** Partner codes for a retail partner with a contract directly towards a NSC, mainly B2B. */
  DirectCustomer = 'DIRECT_CUSTOMER',
  /** Partner codes used for steering fleet leads from .com. */
  FleetLeads = 'FLEET_LEADS',
  /** IMPORTER */
  Importer = 'IMPORTER',
  /** NSC */
  Nsc = 'NSC',
  /** Partner codes used for ordering of internal cars or cars for internal use (i.e. Demo cars, press etc.). */
  NscInternalUse = 'NSC_INTERNAL_USE',
  /** PARTS_WAREHOUSE */
  PartsWarehouse = 'PARTS_WAREHOUSE',
  /** Partner codes used for volvo Popup stores, which are temporary 'stores', mainly used for communication on .com. */
  PopupStore = 'POPUP_STORE',
  /** Partner codes used for distribution of cars to certain locations. */
  Ports = 'PORTS',
  /** Partner codes for rental & finance company with a contract directly towards NSC. */
  RentalFinanceCustomer = 'RENTAL_FINANCE_CUSTOMER',
  /** Partner codes used for communication on .com and sales at volvo Studios. */
  Studio = 'STUDIO',
}

export type SpecialDate = {
  __typename?: 'SpecialDate';
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  timings: Array<TimeInterval>;
};

export enum TerritoryType {
  /** Selling represents the territory/ies that are assigned to the retailer with the primary responsibility for car sales */
  Selling = 'Selling',
  /** Servicing represents the territory/ies that are assigned to the retailer with the primary responsibility for car servicing */
  Servicing = 'Servicing',
}

export type TimeInterval = {
  __typename?: 'TimeInterval';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type Urls = {
  __typename?: 'Urls';
  digitalWeb?: Maybe<Scalars['String']>;
  languageCode: Scalars['String'];
  localOffers?: Maybe<Scalars['String']>;
  mapLocation?: Maybe<Scalars['String']>;
  newCarLocator?: Maybe<Scalars['String']>;
  partsOffer?: Maybe<Scalars['String']>;
  polestarServiceBooking?: Maybe<Scalars['String']>;
  retailer?: Maybe<Scalars['String']>;
  usedCarLocator?: Maybe<Scalars['String']>;
  workshop?: Maybe<Scalars['String']>;
};
