/**
 * An object where the key is the language code and the value is
 *
 *  - The name of the language in English
 *  - An optional boolean (default false) specifying if the language is Right To Left
 *
 * Find the name of a language in https://github.com/unicode-cldr/cldr-localenames-full
 */
export const languages = {
  ar: ['Arabic', true],
  az: ['Azerbaijani'],
  be: ['Belarusian'],
  bg: ['Bulgarian'],
  bs: ['Bosnian'],
  cs: ['Czech'],
  da: ['Danish'],
  de: ['German'],
  el: ['Greek'],
  en: ['English'],
  es: ['Spanish'],
  et: ['Estonian'],
  fi: ['Finnish'],
  fr: ['French'],
  he: ['Hebrew', true],
  hr: ['Croatian'],
  hu: ['Hungarian'],
  hy: ['Armenian'],
  id: ['Indonesian'],
  il: ['Hebrew', true],
  is: ['Icelandic'],
  it: ['Italian'],
  ja: ['Japanese'],
  ka: ['Georgian'],
  ko: ['Korean'],
  km: ['Khmer'],
  lt: ['Lithuanian'],
  lv: ['Latvian'],
  mk: ['Macedonian'],
  no: ['Norwegian'],
  nl: ['Dutch'],
  pl: ['Polish'],
  pt: ['Portuguese'],
  ro: ['Romanian'],
  'ro-MD': ['Moldavian'],
  ru: ['Russian'],
  sk: ['Slovak'],
  sl: ['Slovenian'],
  sq: ['Albanian'],
  'sr-Latn': ['Serbian'],
  cnr: ['Montenegrin'],
  sv: ['Swedish'],
  th: ['Thai'],
  tr: ['Turkish'],
  uk: ['Ukrainian'],
  vi: ['Vietnamese'],
  zh: ['Chinese'],
} as const;

export type LanguageCode = keyof typeof languages;
